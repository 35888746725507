import { Image, Rate } from "antd";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRouter } from "next/router";
import style from "../../../../styles/main.module.css";

export default function Testimonial() {
  const { t, i18n } = useTranslation("common");
  const router = useRouter();

  const prevRef = useRef();
  const nextRef = useRef();

  const [swiper, setSwiper] = useState();
  const isEn = i18n.language === "en";

  const testimonials_data = [
    {
      id: 1,
      description:
        "Diese Reise werde ich nie vergessen, es war unglaublich schön und hat Körper und Seele so gut getan! ☺️ Chris hat für uns alles möglich gemacht und war einfach klasse! 💪🏼 Ich kann diese Erfahrung nur jedem ans Herz legen 🧘🏻‍♀️🏔🪂☀️🫶🏼",
      image:
        "https://api.moverii.de/cdn/images/multimedia/l5GdS1jKX0racCy81mH3oCJyjO1xVz4nknVNtCTd.jpg",
      name: "Laura",
      offer_title: {
        en: "Varied outdoor & yoga retreat at 1800 meters",
        de: "Abwechslungsreiches Outdoor- & Yoga-Retreat auf 1800 Metern",
      },
      offer_url: "globeseeker-sommer-outdoor-und-yoga-retreat",
      rate: 5,
      ratingId: 2017,
    },
    {
      id: 2,
      description:
        "Raquel und Marco sind unglaublich herzliche Gastgeber die euch auf allen Ebenen verwöhnen werden. Ich komme sicher wieder. Vielen Dank für die tolle Zeit und hoffentlich bis bald❤",
      image:
        "https://api.moverii.de/cdn/images/multimedia/jWSCN1sSrbMq0knLrGr3p1kDYkrJSv0R5RPUvELG.jpg",
      name: "Christina Schriefl",
      offer_title: {
        en: "Family surf house in the untouched north of Portugal",
        de: "Familiäres Surfhaus im unberührten Norden Portugals",
      },
      offer_url: "estela-surfhostel-familiaerer-surfurlaub-estela-portugal",
      rate: 5,
      ratingId: 2855,
    },
    {
      id: 3,
      description:
        "Ich habe den Retreat gebucht, weil ich gefühlt nur noch aus Kopf bestand. Als ich nach einigen Tagen zurückreiste, musste ich anhalten, um vor Glück zu weinen. Die Umgebung, die Menschen, die Programme haben mich fast magisch aufgefüllt mit Freude, Licht und Energie. Es hält an, obwohl ich sofort wieder in den Business-Alltag eingetaucht bin. Ich spüre eine tiefe Befriedigung und vor allem eine grosse Dankbarkeit. Ich wollte eine Veränderung und diese ist mir zugeflogen wie ein Geschenk. Ich werde noch lange davon zehren.",
      image:
        "https://api.moverii.de/cdn/images/multimedia/70847_7JLGCv93Dg47IN0qe4qBfoe6a_1716560187.jpg",
      name: "Anita Chiani",
      offer_title: {
        en: "Yoga & fitness retreat: mind, body and heart in harmony",
        de: "Yoga & Fitness Retreat: Geist, Körper und Herz im Einklang",
      },
      offer_url:
        "retreat-yoga-fitness-drive-the-mind-shape-the-body-open-the-heart",
      rate: 5,
      ratingId: 2910,
    },
    {
      id: 4,
      description:
        "Tolle Lage der Apartments, sehr lehrreicher Kurs und mehr als schöne Atmosphäre...ich habe mein Herz an das Surfen verloren und werde definitiv zurückkommen!",
      image:
        "https://api.moverii.de/cdn/images/multimedia/u4gdYQvxidlVeuzLuOczEetObJbFkFtqDuG7Hl9R.jpg",
      name: "Lara",
      offer_title: {
        en: "Surf vacation in private apartments in the heart of Biarritz",
        de: "Surfurlaub in privaten Apartments im Herzen von Biarritz",
      },
      offer_url: "paradise-surf-urlaub-biarritz-frankreich",
      rate: 5,
      ratingId: 2180,
    },
    {
      id: 5,
      description:
        "Das war meine erste Reise dieser Art und auch meine erste Reise allein. Und da ich dieses Mal alleine vereist bin, habe ich mich genau für diese Art Urlaub entschieden und würde es immer wieder machen. Man ist jeden Tag beim Essen oder den Aktivitäten in einer netten Gruppe zusammen und lernt dabei viele tolle Menschen kennen. Das Angebot, welches um die Aktivitäten drumherum geplant wird ist abwechslungsreich, ob Cocktailabend, Weinverkostung oder Olivenöl-Tasting. Alles kann, nichts muss. Das Motto, was die komplette Reise beschreibt. Absolute Empfehlung, es war wirklich toll.",
      image:
        "	https://api.moverii.de/cdn/images/multimedia/sn4kE4DR5JlGCoZJ5sdQdUVtRcwfo0rzCeq7sBch.jpg",
      name: "Nanja",
      offer_title: {
        en: "Yoga, Fitness & Hiking under the Tuscan Sun",
        de: "Yoga, Fitness & Wandern unter der toskanischen Sonne",
      },
      offer_url: "tuscan-fitness-yoga-fitness-wandern-montegonzi-italien",
      rate: 5,
      ratingId: 2926,
    },
    {
      id: 6,
      description:
        "Einer der besten Urlaube meines Lebens. Die Surfschule war gut organisiert, der Flughafentransfer hat geklappt und das Hostel wurde jeden Tag gereinigt. Die Surflehrer waren super nett und haben mir sehr geholfen. Bis bald",
      image:
        "https://api.moverii.de/cdn/images/multimedia/A883pKn3uVkaFoSvXcrnWeKcoHSKWavCf1GtiTXl.jpg",
      name: "Nikolas Kutz",
      offer_title: {
        en: "Surf camp in Corralejo: chilling out, surfing & meeting new people",
        de: "Surfcamp in Corralejo: Chillen, Surfen & neue Leute",
      },
      offer_url: "homegrown-surfcamp-corralejo-kanarische-inseln",
      rate: 5,
      ratingId: 2923,
    },
    {
      id: 7,
      description:
        "Awesome experience 🌞 I was able to enjoy a fully organised week of surfing, yoga & skating! Awesome breakfast & dinner freshly prepared, super nice hostel managers! Loved it there, so I highly recommend it :) Also the sand boarding was a breathtaking experience!!!",
      image:
        "https://api.moverii.de/cdn/images/multimedia/53738_uAtfJJF2jnuU8hoECyveaEvuj_1732152006.webp",
      name: "Nina-Sophie Müller",
      offer_title: {
        en: "Surfing, yoga and skating - experience active days in Morocco",
        de: "Surfen, Yoga und Skaten - Erlebe aktive Tage in Marokko",
      },
      offer_url: "cactus-surf-skate-yoga-travel",
      rate: 5,
      ratingId: 2978,
    },
    {
      id: 8,
      description:
        "Steve und Michaela waren super süß und bemüht, alles zu organisieren und einem angenehm zu gestalten. Das gesunde Essen war wirklich super und hat gut getan. Viel Sport, gutes Essen und warme Sonne - perfekt! Vor allem für HIIT und Kampfsportler interessant wegen Krav Maga und Sparring.",
      image:
        "https://api.moverii.de/cdn/images/multimedia/jhSVeXSEP1DMkxU9IDPlldPjzF5NF9pMbWivgWeP.jpg",
      name: "Franziska Nicolai",
      offer_title: {
        en: "Tenerife boot camp: get fit in a vacation paradise",
        de: "Teneriffa Bootcamp: Fit werden im Urlaubsparadies",
      },
      offer_url: "coster-bootcamp-urlaub-adeje-teneriffa",
      rate: 5,
      ratingId: 2966,
    },
    {
      id: 9,
      description:
        "Ich hatte eine sehr schöne Woche im Surfcamp von Umberto. Umberto ist ein sehr humorvoller und engagierter Surflehrer. Das Surfhaus ist sehr gepflegt und sauber. Es ist mehr wie eine WG als ein Hostel. Vor allem der liebevolle Host Iker und die tolle Dachterasse machen das Surfhaus aus. Es werden neben dem Surfen täglich Aktivitäten angeboten zu fairen Preisen. Vor allem den Ausflug auf Teide kann ich sehr empfehlen.",
      image:
        "https://api.moverii.de/cdn/images/multimedia/oVQcgL2aM1N0HP4d2eVwgRJtxKWeo8JU4O59eA4z.jpg",
      name: "Svenja Zünd",
      offer_title: {
        en: "Modern surf & yoga house with sun terrace on Tenerife",
        de: "Modernes Surf & Yoga-Haus mit Sonnenterrasse auf Teneriffa",
      },
      offer_url: "volcano-surf-und-yoga-camp-adeje-kanaren",
      rate: 5,
      ratingId: 2929,
    },
    {
      id: 10,
      description:
        "Es war wunderschön dort! Alle Bereiche (Essensbereich, Yogashala, Schwimmbecken, Dorm) sehen super aus und sind sauber. Das Team vor Ort ist unglaublich nett und kümmert sich super um alle Anfragen und das Essen war unglaublich lecker! Die Lage zum Strand und coconut tree hill ist ebenfalls ideal :)",
      image:
        "https://api.moverii.de/cdn/images/multimedia/44444_o2W0qrHo6Ly2VgKGAINzdduUF_1724928491.png",
      name: "Clara Hascher",
      offer_title: {
        en: "Keep the balance: surfing and yoga in the tropical paradise of Sri Lanka",
        de: "Keep the balance: Surfen und Yoga im tropischen Paradies Sri Lanka",
      },
      offer_url:
        "keep-the-balance-surfing-and-yoga-in-the-tropical-paradise-of-sri-lanka",
      rate: 5,
      ratingId: 2921,
    },
    {
      id: 11,
      description:
        "Die Organisation und der Kontakt vor der Reise waren richtig gut und vor Ort gab es dann viele super Erlebnisse und eine tolle Betreuung. Ein einfach rundum gelungener Urlaub! Ich komme gerne wieder!",
      image:
        "https://api.moverii.de/cdn/images/multimedia/Tl0NPzw9AaGrEAjvErX4qtovMmnU8t5cTRitPRVQ.jpg",
      name: "Verena Scheel",
      offer_title: {
        en: "Plaisir climbing on the Croatian peninsula of Istria",
        de: "Plaisirklettern auf der kroatischen Halbinsel Istrien",
      },
      offer_url: "goodtimes-kletterreise-istrien-kroatien",
      rate: 5,
      ratingId: 2895,
    },
    {
      id: 12,
      description:
        "Ich blicke zurück auf eine Woche mit vielen tollen Erlebnisse und genialen Leuten. Man konnte erste Erfahrungen auf dem Surfboard sammeln und gleichzeitig eine super Stimmung im Camp erleben.",
      image:
        "https://api.moverii.de/cdn/images/multimedia/fzaag5fHah9tM6yqchfBd1UVwBr39CwswPeQCZrv.jpg",
      name: "Sophia",
      offer_title: {
        en: "Surfing, beach and city life in Lisbon",
        de: "Surfen, Strand und Stadtleben in Lissabon",
      },
      offer_url: "surfblend-surfinn-lissabon-portugal",
      rate: 5,
      ratingId: 2227,
    },
  ];

  useEffect(() => {
    if (swiper) {
      swiper?.navigation.init();
      swiper?.navigation.update();
    }
  }, [swiper]);

  return (
    <div
      className={`${classNames(
        style.travel_pos,
        "mt-[50px] lg:mt-[100px] px-4 md:px-0"
      )}`}
    >
      <div className="container mx-auto px-2 lg:px-0">
        <h3 className="font-black md:text-center catamaran text-[28px] pb-[24px] md:pb-[30px]">
          {t("frontpage.testimonial")}
        </h3>

        <Swiper
          nextButton={nextRef}
          prevButton={prevRef}
          onSwiper={setSwiper}
          loop={true}
          modules={[Navigation]}
          className="mySwiper mb-5 md:mb-0"
          initialSlide={0}
          // grabCursor={true}
          // on
          // observer
          // observeParents
          navigation={{
            nextEl: nextRef?.current,
            prevEl: prevRef?.current,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            560: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            910: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {testimonials_data?.map((v) => {
            return (
              <SwiperSlide key={v.ratingId}>
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    typeof window !== undefined &&
                      window.open(
                        `https://moverii.de/offer/${v?.offer_url}`,
                        "_blank"
                      );
                  }}
                >
                  <div className="box-testo">
                    <div className="justify-between py-1 flex">
                      <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between space-x-1 pb-1 w-full">
                        <h6 className="font-bold text-lg pr-1">{v.name}</h6>
                        <div className="!-ml-[1px] md:ml-0">
                          <Rate
                            style={{ fontSize: "16px", color: "#ffc312" }}
                            value={v.rate}
                            disabled={true}
                            classNames="flex"
                          />
                        </div>
                      </div>
                    </div>

                    <p className={style.des_travel}>{v.description}</p>

                    <div className="flex border-t pt-4 mt-2 border-gray-50/10 items-center gap-3">
                      <div className="w-[40px] h-[40px]">
                        <Image
                          src={
                            v?.image ||
                            "/static/images/placeholder/provider.jpg"
                          }
                          alt={isEn ? v.offer_title.en : v.offer_title.de}
                          className="object-center object-cover !rounded-lg"
                          preview={false}
                          width={40}
                          height={40}
                          fallback="/static/images/placeholder/provider.jpg"
                          loading="lazy"
                        />
                      </div>

                      <p className="font-bold line-clamp-2">
                        {isEn ? v.offer_title.en : v.offer_title.de}
                      </p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className={"flex justify-center md:mt-5"}>
          <div className={"flex flex-row space-x-4 items-center"}>
            <div className={" cursor-pointer w-8"} ref={prevRef}>
              <img src={"/static/icons/arl.svg"} alt="arrow left icon" />{" "}
            </div>
            <div className={" cursor-pointer w-8"} ref={nextRef}>
              <img src={"/static/icons/arr.svg"} alt="arrow right icon" />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
