import DesktopSearchBar from "@components/NewSearchBar/HomeSearchBar/DesktopSearchBar";
import { useTranslation } from "react-i18next";
import dynamic from "next/dynamic";
import { useState } from "react";
import { IoIosSearch } from "react-icons/io";
import CustomImage from "@components/CustomImage/CustomImage";

const MobileSearch = dynamic(
  () => import("@components/NewSearchBar/HomeSearchBar/MobileSearch"),
  {
    ssr: false,
  }
);

const HomeHeroBanner = () => {
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const { t } = useTranslation("common");

  return (
    <section className="w-full">
      {/* desktop */}
      <div className="h-[520px] md:h-[550px] lg:h-[530px] w-full home_banner_bg flex-col hidden md:flex">
        <div className="container px-4 md:px-0 pb-44 md:pb-0">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-white md:text-[52px] catamaran leading-tight text-[48px] sm:text-[52px] font-black drop-shadow-lg">
              {t("frontpage.banner_text_title")}
            </h1>
            <h2 className="text-white text-2xl md:text-[26px] lg:text-[30px] font-semibold drop-shadow-lg">
              {t("frontpage.subtitle_banner")}
            </h2>
          </div>
        </div>

        <DesktopSearchBar type="home" />
      </div>

      {/* mobile */}
      <div className="w-full md:hidden">
        <div className="w-full h-[220px] bg-[#FDD59E] flex justify-center items-center p-4">
          <h1 className="text-center catamaran text-[32px] text-[#2B2D42] font-black -mt-5">
            {t("frontpage.banner_text_title_mobile")}
          </h1>
        </div>
        {/* search box */}
        <div className="px-6 w-full">
          <div
            onClick={() => setIsMobileSearchOpen(true)}
            className="w-full border-[3px] border-[#12A3BA] shadow-sm rounded-[100px] min-h-[62px] flex items-center justify-start gap-2 px-4 -mt-[30px] bg-white py-2 cursor-pointer"
          >
            <div className="w-[25px] flex">
              <IoIosSearch className="text-[25px]" />
            </div>
            <p className="text-[#2B2D42] text-base font-semibold">
              {t("frontpage.banner_search_box_text_mobile")}
            </p>
          </div>
        </div>

        {/* Image composition */}
        <div className="w-full h-[450px] max-w-lg mx-auto">
          <div className="w-full h-full md:py-0 scale-75 sm:scale-90 md:scale-75 lg:scale-95 relative flex justify-center">
            <span className="absolute z-30 w-[200px] md:h-[150px] lg:h-[183px] top-[20px] md:top-[10px] lg:top-[20px] -left-[30px] lg:left-[0px]">
              <CustomImage
                src="static/global/newsletter/newsletter-1.webp"
                alt="moverii newsletter 1"
                loading="eager"
                urlWidth={300}
                className="object-center object-cover rounded-[20px] shadow-md"
                format="webp"
              />
            </span>
            <span className="z-10 md:w-[250px] lg:w-[338px] h-[410px]">
              <CustomImage
                src="static/global/newsletter/newsletter-2.webp"
                alt="moverii newsletter 2"
                loading="eager"
                width={338}
                urlWidth={600}
                className="object-center object-cover rounded-[20px] shadow-md"
                format="webp"
              />
            </span>
            <span className="absolute z-30 bottom-1 w-[170px] h-[132px] -right-[30px] sm:right-[10px]">
              <CustomImage
                src="static/global/newsletter/newsletter-3.webp"
                alt="moverii newsletter 3"
                loading="eager"
                width={170}
                urlWidth={300}
                className="object-center object-cover rounded-[20px] shadow-md"
                format="webp"
              />
            </span>
          </div>
        </div>
      </div>

      {isMobileSearchOpen && (
        <MobileSearch
          open={isMobileSearchOpen}
          setOpen={setIsMobileSearchOpen}
        />
      )}
    </section>
  );
};

export default HomeHeroBanner;
