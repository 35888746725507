import CustomImage from "@components/CustomImage/CustomImage";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const MultipleSlidesExample = ({ data, shadowColor, isHome }) => {
  const { t, i18n } = useTranslation("common");
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);
  const isEn = i18n.language === "en";

  const properties = {
    nextArrow: <div>{`>`}</div>,
    prevArrow: <div>{`<`}</div>,
    dots: false,
    arrows: true,
    infinite: false,
    centerMode: false,
    centerPadding: 25,
    autoplay: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipe: true,
    dotsClass: "slick-dots slick-thumb slick-dots-52",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },

    beforeChange: (oldIndex, newIndex) => {
      if (newIndex == data?.length - 5) {
        setShowRight(false);
      } else {
        setShowRight(true);
      }
      if (newIndex < 5) {
        setShowLeft(false);
      } else {
        setShowLeft(true);
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          arrows: false,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          swipeToSlide: true,
          swipe: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1.7,
          slidesToScroll: 1,
          swipeToSlide: true,
          swipe: true,
        },
      },
    ],
  };
  const shadowColordefault = shadowColor || "from-white";

  return (
    <section className={"container mx-auto"}>
      <h2
        className={
          "font-black text-[28px] catamaran flex md:justify-center pb-[24px] md:pb-[30px] text-left md:text-center"
        }
      >
        {t("frontpage.Popular destinations") || t("main.Beliebte Reiseziele")}
      </h2>

      <div className="relative px-2 md:px-0">
        {showRight && (
          <div
            className={`absolute hidden lg:flex h-full ${
              isHome
                ? "home_popular_slider_shadow_right"
                : `${shadowColordefault} bg-gradient-to-l max-w-md`
            } right-0 top-0 z-20`}
            style={{ width: "150px" }}
          ></div>
        )}
        {showLeft && (
          <div
            className={`absolute hidden lg:flex h-full ${
              isHome
                ? "home_popular_slider_shadow_left"
                : `${shadowColordefault} bg-gradient-to-r max-w-md`
            } left-0 top-0 z-20`}
            style={{ width: "150px" }}
          ></div>
        )}

        <Slider {...properties}>
          {data?.map((v, i) => {
            return (
              <a
                key={v.distinction_alias + i}
                href={
                  process.env.NEXT_PUBLIC_SITE_ADDRESS +
                  "s/moverii?page=1&" +
                  v.link
                }
                className="z-10 min-h-[100px] pr-2"
              >
                <div className="relative overflow-hidden rounded-[20px]">
                  <CustomImage
                    src={`static/home/destinations/${v.image}.webp`}
                    alt={isEn ? v.distinction_title_en : v.distinction_title_de}
                    urlWidth={350}
                    className="object-cover object-center"
                    fallback="/static/images/placeholder/provider.jpg"
                  />
                  <h5 className="absolute bottom-4 left-4 z-20 text-sm md:text-base font-bold text-white">
                    {isEn ? v.distinction_title_en : v.distinction_title_de}
                  </h5>
                  <span className="absolute bottom-0 right-0 left-0 bg-gradient-to-t from-black to-transparent w-full h-[40%] z-10" />
                </div>
              </a>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default MultipleSlidesExample;
