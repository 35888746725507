import classNames from "classnames";
import { useTranslation } from "react-i18next";
import style from "../../../styles/main.module.css";
import CustomImage from "@components/CustomImage/CustomImage";
import { IoCheckmarkOutline } from "react-icons/io5";

export default function Experience() {
  const { t } = useTranslation("common");

  return (
    <div className="container mx-auto mt-[50px] lg:mt-[100px] px-4 md:px-0">
      <div
        className={classNames(
          "flex justify-between flex-col-reverse lg:flex-row mx-5 gap-10 lg:gap-0 lg:items-center"
        )}
      >
        <div
          className={classNames(
            style.video_exp_box,
            "hidden md:flex w-full lg:mr-10 md:max-h-[20rem] max-h-[50rem] justify-center rounded-2xl my-auto mx-auto lg:mx-0"
          )}
        >
          <CustomImage
            src="static/home/moverii_journey.webp"
            alt="moverii.de"
            urlWidth={900}
            height={350}
            format="webp"
            fallback="/static/images/placeholder/provider.jpg"
            className="rounded-2xl w-full object-cover flex self-center"
          />
        </div>

        <div
          className={classNames(
            style.experience_detail,
            "lg:ml-8 px-2 sm:px-0 -mx-4 sm:mx-0"
          )}
        >
          <div className={"flex flex-row my-auto py-[24px] md:-ml-5"}>
            <h3 className={"!font-black catamaran inline-block lg:-ml-2"}>
              <span
                className={
                  "inline-block text-[24px] md:text-[28px] !font-black"
                }
              >
                {t("frontpage.section_experience_title")}
              </span>
              <span className={"inline-block align-middle -mt-[15px] px-2"}>
                <CustomImage
                  src="static/logo/moverii_logo.svg"
                  alt="moverii.de"
                  loading="lazy"
                  width={95}
                />
              </span>
              <span
                className={
                  "inline-block text-[24px] md:text-[28px] !font-black"
                }
              >
                {t("frontpage.section_experience_title2")}
              </span>
            </h3>
          </div>

          <div className="flex flex-col space-y-3 gap-y-2 md:-ml-5">
            {[1, 2, 3, 4, 5].map((item) => (
              <div
                key={"experience-moverii-item-" + item}
                className="flex flex-row items-center gap-2"
              >
                <div className="flex">
                  <IoCheckmarkOutline className="text-[32px] text-[#1abc9c]" />
                </div>
                <p className="text-lg">
                  ... {t("frontpage.home_experience_item_" + item)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
