import request from "@libs/request/request";
import BaseLayout from "../src/ui/layouts/base-layout";
import HomeHeroBanner from "@components/frontpage/HomeHeroBanner";
import HomeSectioncomminity from "@components/frontpage/main_section_comminity";
import Discover from "@components/frontpage/category";
import Destination from "@components/frontpage/destination";
import Experience from "@components/frontpage/exprience";
import Testimonial from "@components/frontpage/traveler/testimonial";
import Traveler from "@components/frontpage/blog";
import { inject, observer } from "mobx-react";
import { i18n, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { LogoJsonLd, NextSeo } from "next-seo";
import { useEffect, useState } from "react";
import NewOfferList from "@components/frontpage/NewOfferList";

const IndexPage = inject("CoreStore")(
  observer(() => {
    const [offerData, setOfferData] = useState([]);
    const [hasFailed, setHasFailed] = useState(false);
    const { t } = useTranslation("common");

    const getData = async () => {
      try {
        setHasFailed(false);
        const res = await request.get("search/v2/selected-offers");
        setOfferData(res?.data?.search_data?.items || []);
      } catch (err) {
        setHasFailed(true);
        console.log(err.response?.data || err.message);
      }
    };

    useEffect(() => {
      getData();
    }, []);

    return (
      <div>
        <NextSeo
          title={t("tag.main_meta_title")}
          description={t("tag.main_meta_description")}
          canonical={process.env.NEXT_PUBLIC_SITE_ADDRESS}
          openGraph={{
            url: process.env.NEXT_PUBLIC_SITE_ADDRESS,
            title: `${t("tag.main_meta_title")}`,
            description: `${t("tag.main_meta_description")}`,
            site_name: "moverii.de",
          }}
          twitter={{
            handle: "@handle",
            site: "@site",
            cardType: "summary_large_image",
          }}
        />
        <LogoJsonLd
          logo={
            process.env.NEXT_PUBLIC_IMAGE_CDN + "static/logo/moverii_logo.svg"
          }
          url={process.env.NEXT_PUBLIC_SITE_ADDRESS}
        />

        <main className="bfront">
          <HomeHeroBanner />
          <div className="hidden md:block">
            <HomeSectioncomminity isHome />
          </div>
          <Discover isHome />
          <Destination isHome />
          <Experience />
          <NewOfferList
            data={offerData}
            hasFailed={hasFailed}
            getData={() => getData()}
            isHome
          />
          <Testimonial />
          <Traveler />
          <div className="md:hidden pb-10">
            <HomeSectioncomminity isHome />
          </div>
        </main>
      </div>
    );
  })
);
IndexPage.Layout = BaseLayout;

export const getServerSideProps = async ({ locale }) => {
  if (process.env.NODE_ENV === "development") {
    await i18n?.reloadResources();
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default IndexPage;
