import style from "../../../styles/main.module.css";
import useWindowSize from "src/hooks/useWindowSize";
import CustomImage from "@components/CustomImage/CustomImage";
import { Image } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Discover(props) {
  const [showWhiteShadowLeft, setShowWhiteShadowLeft] = useState(false);
  const [showWhiteShadowRight, setShowWhiteShadowRight] = useState(true);

  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const isHome = props?.isHome;
  const category = props?.data || [];

  const categoryNewData = [
    {
      alias: "surf",
      id: 3,
      image:
        process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
        "cdn/statics/Homepage/Sports/Surfcamps.jpg?w=450",
      label: "Surfen",
      offers: 135,
      title: "Surfen",
      link: process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?sport[]=surf",
    },
    {
      alias: "yoga",
      id: 2,
      image:
        process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
        "cdn/statics/Homepage/Sports/Yoga-Retreats.jpg?w=450",
      label: "Yoga",
      offers: 129,
      title: "Yoga",
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?page=1&sport=yoga",
    },
    {
      alias: "hiking",
      id: 4,
      image:
        process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
        "cdn/statics/Homepage/Sports/Wanderurlaub.jpg?w=450",
      label: "Wandern",
      offers: 43,
      title: "Wandern",
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?page=1&sport=hiking",
    },
    {
      alias: "martial-arts",
      id: 6,
      image:
        process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
        "cdn/statics/Homepage/Sports/Kampfsportcamps.jpg?w=450",
      label: "Kampfsport",
      offers: 19,
      title: "Kampfsport",
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=martial-arts",
    },
    {
      alias: "fitness",
      id: 5,
      image:
        process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
        "cdn/statics/Homepage/Sports/Fitnessreisen.png?w=450",
      label: "Fitness",
      offers: 59,
      title: "Fitness",
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?page=1&sport=fitness",
    },
  ];

  const HomecategoryNewData = [
    {
      image: "1",
      title: t("frontpage.new_categories_1"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?page=1&sport=surf",
    },
    {
      image: "2",
      title: t("frontpage.new_categories_2"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?page=1&sport=yoga",
    },
    {
      image: "3",
      title: t("frontpage.new_categories_3"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?page=1&sport=hiking",
    },
    {
      image: "4",
      title: t("frontpage.new_categories_4"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS + "s/moverii?page=1&sport=fitness",
    },
    {
      image: "5",
      title: t("frontpage.new_categories_5"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=klettern-bouldern-2",
    },
    {
      image: "6",
      title: t("frontpage.new_categories_6"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=bunter-aktivmix",
    },
    {
      image: "7",
      title: t("frontpage.new_categories_7"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=kajak-kanu",
    },
    {
      image: "8",
      title: t("frontpage.new_categories_8"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=martial-arts",
    },
    {
      image: "9",
      title: t("frontpage.new_categories_9"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=fahrrad-mountainbike",
    },
    {
      image: "10",
      title: t("frontpage.new_categories_10"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=kitesurfen",
    },
    {
      image: "11",
      title: t("frontpage.new_categories_11"),
      link:
        process.env.NEXT_PUBLIC_SITE_ADDRESS +
        "s/moverii?page=1&sport=winter-sports",
    },
  ];

  return (
    <>
      {isHome ? (
        <section className="mt-[50px] lg:mt-[100px]">
          <div className="container px-4 md:px-0">
            <h2 className="md:text-center mb-[30px] font-black text-[28px] catamaran">
              {t("frontpage.cat_list")}
            </h2>
            <Swiper
              modules={[Navigation]}
              effect={"fade"}
              navigation={!isMobile}
              pagination={false}
              slidesPerView={1.1}
              freeMode={false}
              spaceBetween={8}
              className="relative"
              onSlideChange={(e) => {
                setShowWhiteShadowLeft(!e?.isBeginning);
                setShowWhiteShadowRight(!e?.isEnd);
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1.1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3.1,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 8,
                },
              }}
            >
              {HomecategoryNewData?.map((cat) => (
                <SwiperSlide
                  key={Number(cat?.image)}
                  className="md:!w-[280px] !h-[300px] !rounded-[20px] !overflow-hidden relative"
                >
                  <a
                    href={cat?.link}
                    target="_blank"
                    className="!w-full !h-full relative !rounded-[20px] !overflow-hidden !shadow-lg"
                  >
                    <CustomImage
                      src={`static/home/sports/${cat?.image}.jpg`}
                      alt={cat?.title}
                      width={isMobile ? "100%" : 280}
                      height={300}
                      urlWidth={800}
                      format="webp"
                      fallback={"/static/images/placeholder/provider.jpg"}
                      className="object-cover object-center z-10"
                    />
                    <p className="absolute w-full h-fit z-30 bottom-4 left-0 right-0 px-4 !text-lg text-white font-bold text-left">
                      {cat?.title}
                    </p>
                    <span
                      className="absolute w-full h-full top-0 bottom-0 z-20"
                      style={{
                        background: "rgb(0,0,0)",
                        background:
                          "linear-gradient(0deg, rgba(0,0,0,0.5494572829131652) 5%, rgba(0,0,0,0) 70%)",
                      }}
                    />
                  </a>
                </SwiperSlide>
              ))}
              {showWhiteShadowLeft && (
                <span
                  className={`absolute hidden lg:flex h-full w-20 bg-gradient-to-r from-white left-0 top-0 z-10 max-w-md`}
                />
              )}
              {showWhiteShadowRight && (
                <span
                  className={`absolute hidden lg:flex h-full w-20 bg-gradient-to-l from-white right-0 top-0 z-10 max-w-md`}
                />
              )}
            </Swiper>
          </div>
        </section>
      ) : (
        <div className={"mt-[50px] lg:mt-[100px]"}>
          {isMobile ? (
            <section className="container mx-auto px-4 md:px-0">
              <h2
                className={
                  "text-center pb-[24px] font-bold text-[28px] catamaran"
                }
              >
                {t("frontpage.cat_list")}
              </h2>
              <div className="grid grid-cols-1 gap-5 px-2 sm:px-0">
                {categoryNewData.map((item, i) => (
                  <a href={item.link} key={"C" + i} className="w-full">
                    <div className="box relative flex text-white box-size  rounded-2xl">
                      <div className={style.gradiantCategory}></div>
                      <div className={"flex relative w-full"}>
                        <Image
                          src={item.image}
                          className={
                            "w-full h-full object-cover absolute rounded-2xl"
                          }
                          alt={item.title}
                          loading="lazy"
                          fallback={"/static/images/placeholder/provider.jpg"}
                          preview={false}
                        />
                        <div className={`${style.detail_offer} pl-4`}>
                          <h5 className={"font-bold text-xl text-white"}>
                            {item.label}
                          </h5>
                          <span className={"text-xs"}>
                            {item.offers} {t("provider.offers")}
                          </span>
                        </div>
                        {item.label}
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </section>
          ) : (
            <>
              <section
                className={
                  "container relative z-20 mx-auto md:pb-20 hidden md:block"
                }
              >
                <h2
                  className={
                    "text-center pb-[30px] font-bold text-[28px] catamaran"
                  }
                >
                  {t("frontpage.cat_list")}
                </h2>

                <div className="grid overflow-hidden md:grid-cols-3 md:grid-rows-2 sm:grid-cols-2 grid-rows-1 gap-4 w-full md:px-0">
                  {categoryNewData.map((item, i) => {
                    if (i == 2) {
                      return (
                        <a
                          className={
                            "md:row-span-2 rounded-2xl row-span-1 md:h-[440px] lg:h-auto"
                          }
                          href={item.link}
                          key={"C" + i}
                        >
                          <div className="box relative flex box-size2 h-full text-white rounded-2xl  ">
                            <div className={style.gradiantCategory}></div>
                            <div className={"relative rounded-2xl flex w-full"}>
                              <img
                                src={item.image}
                                className={"w-100  object-cover rounded-2xl"}
                                alt={item.title}
                              />
                              <div className={`${style.detail_offer} pl-4`}>
                                <h5 className={"font-bold text-xl text-white"}>
                                  {item.label}
                                </h5>
                                <span className={"text-xs"}>
                                  {item.offers} {t("provider.offers")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    } else {
                      return (
                        <a
                          className={"col-span-1  md:h-[212px] lg:h-[250px]"}
                          href={item.link}
                          key={"C" + i}
                        >
                          <div className="box relative flex text-white box-size  rounded-2xl md:h-[212px] lg:h-full">
                            <div className={`${style.gradiantCategory} `}></div>
                            <div className={"flex relative w-full"}>
                              <img
                                src={item.image}
                                className={
                                  "w-full h-full object-cover absolute rounded-2xl md:h-[212px] lg:h-[250px]"
                                }
                                alt={item.title}
                              />
                              <div className={`${style.detail_offer} pl-4`}>
                                <h5 className={"font-bold text-xl text-white"}>
                                  {item.label}
                                </h5>
                                <span className={"text-xs"}>
                                  {item.offers} {t("provider.offers")}
                                </span>
                              </div>
                              {item.label}
                            </div>
                          </div>
                        </a>
                      );
                    }
                  })}
                </div>
              </section>

              <section
                className={
                  "container mx-auto md:px-0 px-5  block md:hidden mt-[50px] lg:mt-[100px]"
                }
              >
                <h2 className={"text-center pb-6 font-bold text-2xl"}>
                  {t("frontpage.cat_list")}
                </h2>

                <Swiper
                  modules={[Navigation, Scrollbar, A11y, Pagination]}
                  slidesPerView={1}
                  style={{ maxHeight: 600 }}
                  spaceBetween={10}
                  effect={"fade"}
                  navigation={true}
                  pagination={{
                    clickable: true,
                  }}
                >
                  {category.map((item, i) => (
                    <SwiperSlide className="">
                      <div
                        className={`flex flex-col items-center justify-center w-full  `}
                      >
                        {" "}
                        <a
                          className={"col-span-1 pb-10 "}
                          href={"/s/moverii/" + item.alias}
                          key={"C" + i}
                        >
                          <div className="box relative flex    text-white box-size  border hover:border-purple-600  rounded-2xl">
                            <div className={style.gradiantCategory}></div>
                            <div className={"flex relative w-full"}>
                              <img
                                src={item.image}
                                className={
                                  "w-full h-full object-cover rounded-2xl"
                                }
                              />
                              <div className={`${style.detail_offer} pl-4`}>
                                <h5 className={"font-bold text-xl text-white"}>
                                  {item.lable}
                                </h5>
                                <span className={"text-xs"}>
                                  {item.lable} offer
                                </span>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>{" "}
                    </SwiperSlide>
                  ))}
                </Swiper>
              </section>
            </>
          )}
        </div>
      )}
    </>
  );
}
