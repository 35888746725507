import classNames from "classnames";
import { observer } from "mobx-react";
import SliderDes from "../../../components/helper/slider";
import style from "../../../styles/main.module.css";

const Destination = observer(({ isHome }) => {
  const Home_slider_data = [
    {
      id: 1,
      link: "country=portugal",
      image: "portugal",
      distinction_alias: "portugal",
      distinction_title_en: "Portugal",
      distinction_title_de: "Portugal",
    },
    {
      id: 2,
      link: "country=deutschland",
      image: "germany",
      distinction_alias: "deutschland",
      distinction_title_en: "Germany",
      distinction_title_de: "Deutschland",
    },
    {
      id: 3,
      link: "country=costa-rica",
      image: "costa-rica",
      distinction_alias: "costa-rica",
      distinction_title_en: "Costa Rica",
      distinction_title_de: "Costa Rica",
    },
    {
      id: 4,
      link: "country=kanarische-inseln",
      image: "canary-islands",
      distinction_alias: "kanarische-inseln",
      distinction_title_en: "Canary Islands",
      distinction_title_de: "Kanaren",
    },
    {
      id: 5,
      link: "country=oesterreich",
      image: "Austria",
      distinction_alias: "oesterreich",
      distinction_title_en: "Austria",
      distinction_title_de: "Österreich",
    },
    {
      id: 6,
      link: "country=thailand",
      image: "thailand",
      distinction_alias: "thailand",
      distinction_title_en: "Thailand",
      distinction_title_de: "Thailand",
    },
    {
      id: 7,
      link: "country=spanien",
      image: "Spain",
      distinction_alias: "spanien",
      distinction_title_en: "Spain",
      distinction_title_de: "Spanien",
    },
    {
      id: 8,
      link: "country=bali",
      image: "bali",
      distinction_alias: "bali",
      distinction_title_en: "Bali",
      distinction_title_de: "Bali",
    },
    {
      id: 9,
      link: "country=italien",
      image: "Italy",
      distinction_alias: "italien",
      distinction_title_en: "Italy",
      distinction_title_de: "Italien",
    },
    {
      id: 10,
      link: "country=morocco",
      image: "morocco",
      distinction_alias: "morocco",
      distinction_title_en: "Morocco",
      distinction_title_de: "Marokko",
    },
    {
      id: 11,
      link: "country=schweden",
      image: "Sweden",
      distinction_alias: "schweden",
      distinction_title_en: "Sweden",
      distinction_title_de: "Schweden",
    },
    {
      id: 13,
      link: "country=frankreich",
      image: "France",
      distinction_alias: "frankreich",
      distinction_title_en: "France",
      distinction_title_de: "Frankreich",
    },
    {
      id: 14,
      link: "country=tuerkei",
      image: "Turkey",
      distinction_alias: "tuerkei",
      distinction_title_en: "Turkey",
      distinction_title_de: "Türkei",
    },
    {
      id: 15,
      link: "state=mallorca",
      image: "Mallorca",
      distinction_alias: "?state=Mallorca",
      distinction_title_en: "Mallorca",
      distinction_title_de: "Mallorca",
    },
    {
      id: 16,
      link: "country=indien",
      image: "India",
      distinction_alias: "indien",
      distinction_title_en: "India",
      distinction_title_de: "Indien",
    },
    {
      id: 17,
      link: "country=griechenland",
      image: "Greece",
      distinction_alias: "griechenland",
      distinction_title_en: "Greece",
      distinction_title_de: "Griechenland",
    },
    {
      id: 18,
      link: "country=norwegen",
      image: "Norway",
      distinction_alias: "norwegen",
      distinction_title_en: "Norway",
      distinction_title_de: "Norwegen",
    },
    {
      id: 19,
      link: "country=Croatia",
      image: "croatia",
      distinction_alias: "croatia",
      distinction_title_en: "Croatia",
      distinction_title_de: "Kroatien",
    },
  ];

  return (
    <div
      className={classNames(
        style.destination_container,
        " py-[50px] md:py-[100px] select-none mt-[50px] lg:mt-[100px] px-3 md:px-0"
      )}
    >
      <SliderDes
        data={Home_slider_data}
        isHome={isHome}
        shadowColor="from-[#e1f9fc]"
        arrowClass="new-arrow-offer"
      />
    </div>
  );
});
export default Destination;
